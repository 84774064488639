<template>
    <div class="roomInfo-container">
        <CSBreadcrumb/>
        <div class="filter-panel">
            <input
                    type="text"
                    placeholder="搜索房间号"
                    class="cs-input"
                    style="border-radius: 4px"
                    v-model="queryRooms.search"
            />
            <CSSelect style="margin-right: 20px">
                <select v-model="queryRooms.state">
                    <option value="">房间状态不限</option>
                    <option
                            v-for="(building,index) in buildingsStart"
                            :key="building"
                            :value="index + 1"
                    >
                        {{ building }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 20px">
                <select v-model="queryRooms.buildingId" @change="changeBuilding">
                    <option value="">全部楼栋</option>
                    <option
                            v-for="building in buildings"
                            :key="building.code"
                            :value="building.code"
                    >
                        {{ building.name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 30px">
                <select v-model="queryRooms.floor">
                    <option value="">楼层不限</option>
                    <option v-for="floor in floorSpace" :value="floor" :key="floor">
                        {{ floor }}
                    </option>
                </select>
            </CSSelect>
            <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    v-on:click="queryRoom()"
            >
                查询
            </button>
        </div>


        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>房间号</th>
                        <th>房间状态</th>
                        <th>所属楼栋楼层</th>
                        <th>关联房间</th>
                        <th>建筑面积<span>(㎡)</span></th>
                        <th>租赁单价<span>(元/㎡/天)</span></th>
                        <th>水表/电表</th>
                        <th>水电公摊承担方</th>
                        <th>业主</th>
                        <th>租客</th>
                        <th>创建人</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="room in roomList" :key="room.roomId">
                        <td class="date-td">{{ room.createTime || "-" }}</td>
                        <td>
                            {{ room.roomNo || "-" }}
                        </td>
                        <td>{{ roomState[room.state] || "-" }}</td>
                        <td>
                            {{ room.buildingName + '-' + room.floor || "-" }}
                        </td>
                        <td>{{roomN}}</td>
                        <td>
                            {{ room.space || "-" }}
                        </td>
                        <td style="min-width: 140px">
                            <template v-if="room.price">
                                {{ room.price / 100 }}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td>
                          <span
                                  v-if="room.waterCount || room.electricityCount"
                                  class="btn-active"
                                  @click="getWater(room.waterMeter,room.electricityMeter)"
                          >{{(room.waterCount || 0 )+ (room.electricityCount || 0) }}个</span
                          >
                            <span v-else>-</span>
                        </td>
                        <td>
                            <span v-if="room.waterAndElectricity === 0">
                {{company || '-'}}
              </span>
                            <span v-else-if="room.waterAndElectricity === 1">
                暂不确定
              </span>
                            <span v-else-if="room.waterAndElectricity === 2">
                {{ room.hydropowerCompanyName }}
              </span>
                            <span v-else>
                -
              </span>
                        </td>
                        <td>{{ room.ownerName ? room.ownerName : "-" }}</td>
                        <td>
                            <template v-if="room.lesseeCompany">
                                <span class="allow-click" @click="lookTenant(room)">查看</span>
                            </template>
                            <template v-else-if="room.relevanceLesseeName">
                                <span class="allow-click" @click="lookTenant(room)">查看</span>
                            </template>
                            <template v-else>-</template>
                        </td>
                        <!--                            <td>
                                                      {{ getBinderName(room.ownerRef) }}
                                                  </td>-->

                        <td>{{ room.createUser || "-" }}</td>
                        <td>
                            <div class="btn-group" v-if="room.state !== 3">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu" style="width: 76px">
                                    <li
                                            v-if="room.state === 2"
                                            @click="changeRoomState(room.roomId, 1)"
                                    >
                                        <a style="width: 100%">启用</a>
                                    </li>
                                    <li
                                            v-if="room.state === 1"
                                            @click="changeRoomState(room.roomId, 2)"
                                    >
                                        <a style="width: 100%">禁用</a>
                                    </li>
                                    <li @click="changeRoomState(room.roomId, 3)">
                                        <a style="width: 100%">作废</a>
                                    </li>
                                    <li @click="upadteRoomInfo(room)">
                                        <a style="width: 100%">修改房间</a>
                                    </li>
                                    <li @click="upadteRoomState(room)">
                                        <a style="width: 100%">修改水电表</a>
                                    </li>

                                </ul>
                            </div>
                            <template v-else>-</template>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" component="pagination"></Pagination>
            <!-- 水电表信息弹出框 -->
            <GetHydropower
                    name="getHydropower"
                    componentName="GetHydropower"
            ></GetHydropower>
            <!-- 修改员工权限 -->
            <EditRoom name="editRoom" componentName="EditRoom"></EditRoom>
            <!-- 修改水电表 -->
            <EditHydroprwer
                    name="editHydroprwer"
                    componentName="EditHydroprwer"
            ></EditHydroprwer>
        </div>
    </div>
</template>

<script>
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import EditHydroprwer from "@/components/EditHydroprwer.vue";
    import {
        queryBuildingUrl,
        queryRoomRentUrls,
        queryIsDivisionRoomUrl,
        changeRoomStateUrl, querySharedConfigUrl
    } from "@/requestUrl";
    import Pagination from "@/components/Pagination.vue";
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";
    import GetHydropower from "@/components/GetHydropower";
    import EditRoom from "@/components/EditRoom.vue";

    export default {
        name: "RoomInfoPrivate",
        components: {
            CSBreadcrumb,
            Pagination,
            CSSelect,
            CSTable,
            GetHydropower,
            EditRoom,
            EditHydroprwer
        },
        created() {
            this.roomN = this.$route.params.roomNo;
            this.queryRooms.roomId = this.$route.params.roomId
            this.getBuildings();
            this.getQuerySharedConfig();
            window.addEventListener("keydown", this._queryRoomMethodDown);
        },
        beforeDestroy() {
            window.addEventListener("keydown", this._queryRoomMethodDown);
        },
        deactivated() {
            window.addEventListener("keydown", this._queryRoomMethodDown);
        },
        mounted() {
            this.$vc.on("room", "listRoom", (_param) => {
                this.queryRoom();
            });
            this.$vc.on("room", "loadData", (_param) => {
                this.queryRoom();
            });
            this.queryRoom();
            this.$vc.on(
                this.$route.path,
                "pagination",
                "page_event",
                (_currentPage) => {
                    this.queryRoom(_currentPage);
                }
            );
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
        },
        updated() {
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
        },
        data() {
            return {
                buildingsStart:['启用','禁用','作废'],
                company: '',
                roomN: '',
                roomState: {
                    1: "启用",
                    2: "禁用",
                    3: "作废",
                },
                filterHeight: 0,
                queryRooms: {
                    search: '',
                    buildingId: '',
                    floor: '',
                    regionCode: this.$vc.getCurrentRegion().code,
                    roomId: '',
                    state: ""
                },
                buildings: [],//全部楼栋
                floorSpace: [],//全部楼层
                roomList: []
            }
        },
        methods: {
            //查询水电费公摊设置
            getQuerySharedConfig() {
                this.$fly.get(querySharedConfigUrl, {
                    regionCode: this.$vc.getCurrentRegion().code
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.company =  res.data.equallySharedCompanyName || "";
                })
            },
            //修改房间
            upadteRoomState(room) {
                this.$vc.emit("editHydroprwer", "openEditHydroprwer", {
                    title: "修改水电表",
                    room,
                });
            },
            upadteRoomInfo(room) {
                this.$vc.emit("editRoom", "openEditRoomModal", {
                    ...room,
                    price: room.price,
                    title: '修改房间'

                });
            },
            /**
             * 修改房间状态
             * @param {Number} id 房间id
             * @param {Number} state 房间状态 1:启用 2:禁用 3:作废
             * */
            changeRoomState(id, state) {
                this.$CSDialog.confirm({
                    title: "提示",
                    message: `确定${this.roomState[state]}吗?`,
                    onConfirm: () => {
                        this.$fly
                            .post(changeRoomStateUrl, {
                                id,
                                state,
                            })
                            .then((res) => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$vc.toast(`${this.roomState[state]}成功`);
                                this.$CSDialog.instance.closeDialog();
                                this.queryRoom();
                            });
                    },
                });
            },
            /**
             * 查看租客
             * @param {Object} roomInfo 租客信息
             * */
            lookTenant(roomInfo) {
                this.$CSDialog.alert({
                    width: "558px",
                    title: "租客",
                    messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${roomInfo.lesseeCompany || roomInfo.relevanceLesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${roomInfo.contractDuration || '-'}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${roomInfo.lesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">手机号</div>
                            <div>${roomInfo.lesseePhone}</div>
                        </div>
                    </div>
                `,
                });
            },
            _queryRoomMethodDown(e) {
                if (e.keyCode == 13) {
                    this.queryRoom();
                }
            },
            /**
             * 修改选中的楼栋
             * @param {Object} e
             * */
            changeBuilding(e) {
                if (e.target.selectedIndex > 0) {
                    const building = this.buildings[e.target.selectedIndex - 1];
                    let floorSpace = [];
                    for (
                        let i = -(building.undergroundFloor || 0);
                        i <= building.aboveGroundFloor;
                        i++
                    ) {
                        if (i !== 0) {
                            floorSpace.push(i);
                        }
                    }
                    this.floorSpace = floorSpace;
                    return;
                }
                this.floorSpace = [];
            },
            //获取水表信息
            getWater(tiem, item2) {
                let times = tiem || [];
                times = times.map(item => {
                    return {
                        name: '水表',
                        value: item
                    }
                })
                let item3 = item2 || [];
                item3 = item3.map(item => {
                    return {
                        name: '电表',
                        value: item
                    }
                })
                let params = times.concat(item3);
                console.log(params);
                this.$vc.emit("getHydropower", "number", {title: "水表/电表", tiem: params});
            },
            // 获取楼栋列表
            getBuildings() {
                this.$fly
                    .post(queryBuildingUrl, {
                        id: "",
                        code: "",
                        regionCode: this.$vc.getCurrentRegion().code,
                        name: "",
                    })
                    .then((res) => {
                        this.buildings = res.data.datas;
                    });
            },
            async queryRoom(pageNo = 1, pageSize = 10) {
                this.$fly.post(queryIsDivisionRoomUrl, {
                    ...this.queryRooms,
                    pageNo,
                    pageSize
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.roomList = res.data.roomData;
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize,
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .btn-active {
        color: #1dafff;
        text-decoration: underline;
        cursor: pointer;
    }
</style>
